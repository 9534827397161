
.FloorMap {
    /*height:85%;*/
    
}
/*
.floorMapContainer {
    position: relative;
    width:100%;
    height:100%;
}
.floorMapInner img {
    max-width:100%;
    max-height:100%
}
.floorMapInner {
    width:100%;
    height:100%;
    position: absolute;
    top:0px;
    left:0px;

}
*/
.pinContainer, .pinContainerInner, .FloorMap , .fmContainer,.floorImg {
    padding:0px;
    margin:0px;
}

.pinContainer {
    position: absolute;


}
.pinContainer img {
    width:100%;
    width:25px;
}
.pinContainerInner {
    position: absolute;
    left:-12px;
    top:-42px;
   
}


.fmContainer {

    min-width:100px;
    min-height:100px;
    position:relative;
    max-width:100%;
    max-height:100%;
    display: inline-block;

}
.floorImg {
    max-width:100%;
    max-height:100%;
    width: auto;
    height: auto;
}
