.profilePic {
    max-width:150px;
    max-height:150px;
    border-radius: 500px;
}
.social-icons a {
    font-size: 1.5rem;
    color: #777;
  }
.social-icons a:hover {
    color: black;
}

