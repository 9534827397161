.blurred, .blurred a {
    font-size:.95em;
    color: transparent;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 9px;

}
/*
.blurred:hover, .blurred a:hover {
   
    color: #666;
    text-shadow: none;

}
*/
.onHoverOnly {
    display:none;
}
.loginToView:hover .blurred, .loginToView:hover > a.onHoverOnly .blurred, a.onHoverOnly:hover .blurred {
    display:none;
}
.loginToView:hover > a.onHoverOnly, a.onHoverOnly:hover {
    display:inline;
}


.profileImage {
	max-width:100px;
}

@media (max-width: 991px) {
	.blurred, .blurred a {
	    font-size:inherit;
	    color: inherit;
	    text-shadow: inherit;
	
	}
}