body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header-legacy .container {
	position:relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Person .row img {

max-width:130px;
max-height:130px;
}
.mapwelcome {
	background-color: rgb(0,0,0,0.4);
}
/* standardize heading styles */

h3, h4, .h3, .h4 {
    font-family: "Trade Gothic W01 Light";
    -webkit-font-smoothing: initial!important;
}

.muted {
    color:#777;
}

#gmap {
    width:100%;
    margin-bottom:2rem;
}

.IfLogin {
    display:inline;
}

#pbJumbotron, #mapJumbotron {
    border-radius:0;
    background-image:url(../static/mission.jpg);
    background-size:cover;
    position:relative;
}

#mapJumbotron {
	background-position:center;
}

#pbJumbotron .LandingHeroSearch .jumbotron {
	background:transparent !important;
}

.mainSearchCont {

}

.mainSearch {
    margin:0px 10%;
    width:80%;
    z-index: 22;
}


#pbsearch, .react-autosuggest__input {
    border-radius:0;
    border:none;
}

#pbSearchButton {
	padding:.65rem !important;
	border-radius:0;
	border:none;
}

.jumbotron.jumbotron-video {background-color: #000;}

#azSearch {
	border:none;
	border-radius:0;
}

.mapLeft a {
	text-decoration:underline;
}

.clear {
    clear:both
}

/* Back to Top button style */
#back-to-top {
	position: fixed;
	bottom: 40px;
	right: 40px;
	z-index: 9999;
	text-align: center;
	cursor: pointer;
	border: 0;
	border-radius: 2px;
	text-decoration: none;
	transition: opacity 0.2s ease-out;
	opacity: 0;
}

#back-to-top.show {
	opacity: 1;
}

/* styles for SCU Login button */

#loginLink:hover a {
	color:#000 !important;
}

#loginLink a:hover {
	background:#fff;
	text-decoration:none;
	color:#000 !important;
}

.header-legacy .nav-dark a.btn-warning {
    color: #000!important;
}

[data-toggle="collapse"] .fa:before {   
  content: "\f139";
}

[data-toggle="collapse"].collapsed .fa:before {
  content: "\f13a";
}


/* md breakpoint */
@media (min-width: 768px) {
  .collapse.menu-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

/* lg breakpoint */
@media (max-width: 992px) {
  .collapse.menu-collapse-md {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

/* xl breakpoint */
@media (min-width: 1200px) {
	#dropdown-basic {
	  font-size:1rem !important;
	}
    #gmap {height:700px;}
}

/* xxl breakpoint */
@media (min-width: 1400px) {
	.container.container-map, header.container-map .container  {width:1340px;max-width:100%;}
}

/* xxxl breakpoint */
@media (min-width: 1600px) {
	.container.container-map, header.container-map .container {width:1520px;max-width:100%;}
}

/* xxxxl breakpoint */
@media (min-width: 1800px) {
	.container.container-map, header.container-map .container {width:1700px;max-width:100%;}
}

@media (max-width: 1199.98px) {
	
	/* corrects the too-small font size change for sans-serif in SCU CSS */
	.header-legacy .text-sans-light.wordmark a {
	    font-size:inherit;
	}
	
	.TopBarSearch form {
	    width:100%
	}
	
	.BrowseSearch .pagination .page-link {
	    padding:.3rem .5rem;
	    font-size:.9rem;
	}
    #gmap {height:600px;}
}

/* lg breakpoint */

@media (max-width: 991.98px) {
	.popover {
		display:none;
	}
  #loginLink {
  	top:105px !important;
  	right:15px !important;
  }
  #dropdown-basic {
    width: 100%;
    text-align: left;
	}
  #ddbrowse {
  	margin-top:50px;
  }
  #gmap {height:500px;}
}

/* md breakpoint */

@media (max-width: 767.98px) {
  .BrowseSearch select { margin-top: 1rem; }
}

/* sm breakpoint */

@media (max-width: 575.98px) {
  .jumbotron.jumbotron-video {background-color: #dfdbd3;}
  .TopBarSearch form { width:100% }
  #gmap {height:350px;}
}

/* xs breakpoint */

@media (max-width: 350px) {
  .TopBarSearch form { width:100% }
  #gmap {height:250px;}
}



/* print stylesheet */

@media print { 

	header, footer, nav, .TopBarSearch, .LandingHeroSearch, .btn {
		display:none;
	}
	
	a {
		text-decoration:none!important;
		color: #000!important;
	}
	
	.HomeLanding .row {
		display:block;
	}
	
	.HomeLanding .row .grid-item {
		width:100% !important;
		max-width:100% !important;
	}
	
	.Position {
		border-bottom:1px solid #999;
	}
	
	.card-deck .card {
		display:block;
		flex:none;
		width:75%;
		border:none;
	}
}

.maplabelcont {
	border:0px;
	position:absolute;
	text-align:center;
	width:120px;
	
} 
.maplabel, .maplabelshadow {
	font-size:1.3em !important;
	position:absolute;
	font-weight:500 !important;
}
.maplabelshadow {
	color: #555 !important; 
	left:0px;
	top:0px;
	width:122px !important;
}
.maplabel {
	width:120px !important;
	color:white !important;
	left:-2px;
	top:-2px;
	text-shadow: 2px 2px 3px #555;
	text-align:center !important;
}

#dropdown-basic {
  margin-left:1px;
  border:none;
  font-size:.85rem;
  padding:.5rem 1rem;
}

#dropdown-basic:hover {
  background-color: #495057 !important;
}

.ddBrowse {
	width:260px;
	max-width:400px;
	padding-left:10px;
}
 .ddBrowse a {
	padding:5px 10px;
	border:1px solid #666;
	width:30px;
	margin:5px;
	display:inline-block
 }

 .permitLetter {
  cursor: pointer;
 }

 .gallery img {
	display: block;
	width:100%;
	height:auto;
	max-height:350px;

 }
 .xsmall {
	font-size:.55em;
 
 }