.orgGrpPos {

    border-bottom: 1px dotted #ddd;
}
.threeColWrap {
    font-size:12px;
   
    column-gap: .8em;
}

/* these media queries arent actually needed for print, but we want it to look like the 3-col layout in the browser so people get the idea they can print it directly */
@media (min-width: 1500px) {
    .threeColWrap {         column-width: 35em;     }
}
@media (max-width: 1500px) {
    .threeColWrap {        column-width: 30em;    }
}
@media (max-width: 1300px) {
    .threeColWrap {          column-width: 25em;    }
}
@media (max-width: 1000px) {
    .threeColWrap {          column-width: 20em;    }
}


@media print {    
    .no-print, .no-print *
    {
        display: none !important;
    }
    .threeColWrap {

        column-width: 25em;
     
    }
    
}