.em_layout_org, .ph_layout_org, .title_layout_org {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.em_layout_org {

    text-align:right;
}
.primary {
    color: #856404;
    background-color: #fff3cd;
    font-weight: bold;
}